<template>

  <div>关注记录管理</div>
</template>

<script>
export default {};
</script>

<style></style>
